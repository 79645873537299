import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  Dropdown,
  ErrorMessage,
  ErrorModal,
  HeaderNavigationBar,
  Option,
  SearchByDateRange,
  SearchByText,
  SearchHeaderReferral,
} from '../../components';
import { ActiveContent } from '../../components/SearchHeaderReferral';
import { spacing, colors } from '../../constants/theme';
import { Button, Table } from '../../core-ui';
import { verifiedStatus } from '../../features/verification/constants/tokoStatus';
import {
  DownloadOrderReferralCodeOnly,
  DownloadOrderReferralCodeOnlyVariables,
} from '../../generated/DownloadOrderReferralCodeOnly';
import {
  DownloadPointRedeemHistory,
  DownloadPointRedeemHistoryVariables,
} from '../../generated/DownloadPointRedeemHistory';
import {
  DownloadStoreOwnerRequestVerification,
  DownloadStoreOwnerRequestVerificationVariables,
} from '../../generated/DownloadStoreOwnerRequestVerification';
import {
  DownloadReferralSalesmanVerifStore,
  DownloadReferralSalesmanVerifStoreVariables,
} from '../../generated/DownloadReferralSalesmanVerifStore';
import {
  GetOrderReferralCodeOnly,
  GetOrderReferralCodeOnlyVariables,
} from '../../generated/GetOrderReferralCodeOnly';
import {
  FilterLoyaltyHistoryInput,
  FilterStoreOwnerSalesReferralCodeInput,
  OrderStatus,
  LoyaltySource,
  RedeemStatus,
  StatusVerify,
  ReferralStorePhotoVerifAdvanceInput,
} from '../../generated/globalTypes';
import {
  LoyaltyPointHistoryRedeem,
  LoyaltyPointHistoryRedeemVariables,
} from '../../generated/LoyaltyPointHistoryRedeem';
import {
  StoreOwnerVerificationReferralCodeAdvance,
  StoreOwnerVerificationReferralCodeAdvanceVariables,
} from '../../generated/StoreOwnerVerificationReferralCodeAdvance';
import {
  ReferralStorePhotoVerifAdvance,
  ReferralStorePhotoVerifAdvanceVariables,
} from '../../generated/ReferralStorePhotoVerifAdvance';
import {
  DOWNLOAD_ORDER_REFERRAL_CODE_ONLY,
  DOWNLOAD_POINT_HISTORY_REDEEM,
  DOWNLOAD_REFERRAL_STORE_PHOTO_VERIF,
  DOWNLOAD_STORE_OWNER_REQUEST_VERIFICATION,
  GET_ORDER_REFERRAL_CODE_ONLY,
  GET_REFERRAL_STORE_PHOTO_VERIF,
  GET_SALESMAN_REFERRAL_CODE,
  GET_STORE_OWNER_REFERRAL_CODE_VERIFICATION,
} from '../../graphql/queries/salesman';

const SalesmanReferralCodeScene = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [storeCode, setStoreCode] = useState('');
  const [storeName, setStoreName] = useState('');
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);
  const [salesmanCode, setSalesmanCode] = useState('');
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);

  const [storeCodeOrder, setStoreCodeOrder] = useState('');
  const [storeNameOrder, setStoreNameOrder] = useState('');
  const [dateFromOrder, setDateFromOrder] = useState<Date | null>(null);
  const [dateUntilOrder, setDateUntilOrder] = useState<Date | null>(null);
  const [salesmanCodeOrder, setSalesmanCodeOrder] = useState('');
  const [orderCode, setOrderCode] = useState('');
  const [distributorCode, setDistributorCode] = useState('');
  const [distributorName, setDistributorName] = useState('');

  const [storeCodeVerif, setStoreCodeVerif] = useState('');
  const [storeNameVerif, setStoreNameVerif] = useState('');
  const [phoneVerif, setPhoneVerif] = useState('');
  const [salesmanCodeVerif, setSalesmanCodeVerif] = useState('');
  const [filingDateFromVerif, setFilingDateFromVerif] = useState<Date | null>(
    null,
  );
  const [filingDateUntilVerif, setFilingDateUntilVerif] = useState<Date | null>(
    null,
  );
  const [
    verifiedDateFromVerif,
    setVerifiedDateFromVerif,
  ] = useState<Date | null>(null);
  const [
    verifiedDateUntilVerif,
    setVerifiedDateUntilVerif,
  ] = useState<Date | null>(null);
  const [selectedVerifStatus, setSelectedVerifStatus] = useState<Option | null>(
    null,
  );

  const [storeCodePhoto, setStoreCodePhoto] = useState('');
  const [storeNamePhoto, setStoreNamePhoto] = useState('');
  const [phonePhoto, setPhonePhoto] = useState('');
  const [salesmanCodePhoto, setSalesmanCodePhoto] = useState('');
  const [filingDateFromPhoto, setFilingDateFromPhoto] = useState<Date | null>(
    null,
  );
  const [filingDateUntilPhoto, setFilingDateUntilPhoto] = useState<Date | null>(
    null,
  );
  const [
    selectedVerifPhotoStatus,
    setSelectedVerifPhotoStatus,
  ] = useState<Option | null>(null);

  const [activeContent, setActiveContent] = useState<ActiveContent>(
    ActiveContent.POINT,
  );

  const [searchDisabled, setSearchDisabled] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState<Option | null>(null);
  const [selectedSource, setSelectedSource] = useState<Option | null>(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<Option | null>(
    null,
  );

  const [queryVariables, setQueryVariables] = useState<
    Partial<FilterLoyaltyHistoryInput>
  >({});

  const [queryOrderVariables, setQueryOrderVariables] = useState<
    Omit<GetOrderReferralCodeOnlyVariables, 'pagination'>
  >({});

  const [queryVerifVariables, setQueryVerifVariables] = useState<
    Omit<FilterStoreOwnerSalesReferralCodeInput, 'pagination'>
  >({});

  const [queryPhotoVerifVariables, setQueryPhotoVerifVariables] = useState<
    ReferralStorePhotoVerifAdvanceInput
  >({});

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const tableStructure = useMemo(
    () => ({
      tadaOrderNumber: {
        headerTitle: t(['Kode Redeem', 'Redeem Code']),
        dataPath: 'tadaOrderNumber',
      },
      storeCode: {
        headerTitle: t(['Kode Toko', 'Store Code']),
        dataPath: 'storeCode',
      },
      storeName: {
        headerTitle: t(['Nama Toko', 'Store Name']),
        dataPath: 'storeName',
      },
      userName: {
        headerTitle: t(['Nama User', 'User Name']),
        dataPath: 'userName',
      },
      phoneNumber: {
        headerTitle: t(['No HP User', 'User Phone Number']),
        dataPath: 'phoneNumber',
      },
      deviceId: {
        headerTitle: t(['Device ID', 'Device ID']),
        dataPath: 'deviceId',
      },
      redeemDate: {
        headerTitle: t(['Tanggal Redeem', 'Redeem Date']),
        dataPath: 'redeemDate',
        orderable: true,
      },
      time: {
        headerTitle: t(['Jam Redeem', 'Redeem Time']),
        dataPath: 'time',
        orderable: true,
      },
      rewardCategory: {
        headerTitle: t(['Kategori Hadiah', 'Reward Category']),
        dataPath: 'rewardCategory',
      },
      rewardName: {
        headerTitle: t(['Nama Hadiah', 'Reward Name']),
        dataPath: 'rewardName',
      },
      pricePerProduct: {
        headerTitle: t(['Harga Hadiah', 'Gift Price']),
        dataPath: 'pricePerProduct',
      },
      quantity: {
        headerTitle: t(['Kuantitas', 'Quantity']),
        dataPath: 'quantity',
      },
      assuranceFee: {
        headerTitle: t(['Asuransi', 'Insurance']),
        dataPath: 'assuranceFee',
      },
      shippingFee: {
        headerTitle: t(['Ongkos Kirim', 'Shipping fee']),
        dataPath: 'shippingFee',
      },
      pointValue: {
        headerTitle: t(['Total Poin', 'Total Points']),
        dataPath: 'pointValue',
        orderable: true,
      },
      oldBalance: {
        headerTitle: t(['Saldo Sebelum', 'Old Balance']),
        dataPath: 'oldBalance',
      },
      newBalance: {
        headerTitle: t(['Saldo Sesudah', 'New Balance']),
        dataPath: 'newBalance',
      },
      source: {
        headerTitle: t(['Sumber', 'Source']),
        dataPath: 'source',
      },
      salesmanCode: {
        headerTitle: t(['Kode Salesman', 'Salesman Code']),
        dataPath: 'salesmanCode',
      },
      status: {
        headerTitle: t(['Status Redeem', 'Redeem Status']),
        dataPath: 'status',
      },
      refundReason: {
        headerTitle: t(['Alasan Gagal', 'Refund Reason']),
        dataPath: 'refundReason',
      },
    }),
    [],
  );

  const orderTableStructure = useMemo(
    () => ({
      storeCode: {
        headerTitle: t(['Kode Toko', 'Store Code']),
        dataPath: 'storeCode',
      },
      storeName: {
        headerTitle: t(['Nama Toko', 'Store Name']),
        dataPath: 'storeName',
      },
      orderDate: {
        headerTitle: t(['Tanggal Order', 'Order Date']),
        dataPath: 'orderDate',
        orderable: true,
      },
      orderNumber: {
        headerTitle: t(['Kode Order', 'Order Code']),
        dataPath: 'orderNumber',
        orderable: true,
      },
      totalAmount: {
        headerTitle: t(['Nilai Order', 'Order Value']),
        dataPath: 'totalAmount',
      },
      agentId: {
        headerTitle: t(['Kode Distributor', 'Distributor Code']),
        dataPath: 'agentId',
      },
      agentName: {
        headerTitle: t(['Nama Distributor', 'Distributor Name']),
        dataPath: 'agentName',
      },
      salesmanReferralCode: {
        headerTitle: t(['Kode Referral Salesman', 'Salesman Referral Code']),
        dataPath: 'salesmanReferralCode',
      },
      salesmanCode: {
        headerTitle: t(['Kode Salesman', 'Salesman Code']),
        dataPath: 'salesmanCode',
      },
      status: {
        headerTitle: t(['Status Order', 'Order Status']),
        dataPath: 'status',
      },
    }),
    [],
  );

  const verifTableStructure = useMemo(
    () => ({
      storeCode: {
        headerTitle: t(['Kode Toko', 'Store Code']),
        dataPath: 'storeCode',
      },
      storeName: {
        headerTitle: t(['Nama Toko', 'Store Name']),
        dataPath: 'storeName',
      },
      userName: {
        headerTitle: t(['Nama User', 'User Name']),
        dataPath: 'userName',
      },
      phoneNumber: {
        headerTitle: t(['No HP', 'Phone Number']),
        dataPath: 'phoneNumber',
      },
      dateOfFiling: {
        headerTitle: t([
          'Tanggal Pengajuan Verifikasi',
          'Verification Request Date',
        ]),
        dataPath: 'dateOfFiling',
        orderable: true,
      },
      salesmanReferralCode: {
        headerTitle: t(['Kode Referral', 'Referral Code']),
        dataPath: 'salesmanReferralCode',
      },
      verificationStatus: {
        headerTitle: t(['Status Verifikasi', 'Verification Status']),
        dataPath: 'verificationStatus',
      },
      verifiedDate: {
        headerTitle: t(['Tanggal Diverifikasi', 'Verified Date']),
        dataPath: 'verifiedDate',
      },
    }),
    [],
  );

  const verifPhotoTableStructure = useMemo(
    () => ({
      managerStoreCode: {
        headerTitle: t(['Kode Toko', 'Store Code']),
        dataPath: 'managerStoreCode',
      },
      storeName: {
        headerTitle: t(['Nama Toko', 'Store Name']),
        dataPath: 'storeName',
      },
      phoneNumber: {
        headerTitle: t(['No HP', 'Phone Number']),
        dataPath: 'phoneNumber',
      },
      firstRevalidationSubmitDate: {
        headerTitle: t([
          'Tanggal Pengajuan Revalidasi',
          'Revalidation Request Date',
        ]),
        dataPath: 'firstRevalidationSubmitDate',
        orderable: true,
      },
      requestVerificationStatus: {
        headerTitle: t(['Status Verifikasi', 'Verification Status']),
        dataPath: 'requestVerificationStatus',
      },
      storePhotoSalesmanReferralCode: {
        headerTitle: t(['Kode Referral', 'Referral Code']),
        dataPath: 'storePhotoSalesmanReferralCode',
      },
    }),
    [],
  );

  const statusOption = useMemo(
    () => [
      { label: 'Success', value: RedeemStatus.Success },
      { label: 'Failed', value: RedeemStatus.Failed },
    ],
    [],
  );

  const sourceOption = useMemo(
    () => [
      { label: 'TADA', value: LoyaltySource.TADA },
      { label: 'DOKU', value: LoyaltySource.DOKU },
    ],
    [],
  );

  const orderStatus = useMemo(
    () => [
      { label: 'CANCELLED', value: 'CANCELLED' },
      { label: 'CLOSE', value: 'CLOSE' },
      { label: 'OPEN', value: 'OPEN' },
      { label: 'PARTIAL', value: 'PARTIAL' },
      { label: 'PENDING', value: 'PENDING' },
    ],
    [],
  );

  const verifStatus = useMemo(
    () => [
      { label: 'Terverifikasi', value: 'Terverifikasi' },
      { label: 'Belum Terverifikasi', value: 'Belum Terverifikasi' },
    ],
    [],
  );

  const tokoStatusOptions = useMemo(
    () =>
      verifiedStatus.map((status) => ({
        label: status,
        value: status,
      })),
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [
    queryVariables,
    queryOrderVariables,
    queryVerifVariables,
    queryPhotoVerifVariables,
  ]);

  const { data, loading, error, refetch } = useQuery<
    LoyaltyPointHistoryRedeem,
    LoyaltyPointHistoryRedeemVariables
  >(GET_SALESMAN_REFERRAL_CODE, {
    variables: {
      pagination: { skip: page * rowsPerPage, first: rowsPerPage },
      filter: queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: orderData,
    loading: orderLoading,
    // error: orderError, currently not used
    refetch: orderRefetch,
  } = useQuery<GetOrderReferralCodeOnly, GetOrderReferralCodeOnlyVariables>(
    GET_ORDER_REFERRAL_CODE_ONLY,
    {
      variables: {
        pagination: { skip: page * rowsPerPage, first: rowsPerPage },
        ...queryOrderVariables,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    data: verifData,
    loading: verifLoading,
    refetch: verifRefetch,
  } = useQuery<
    StoreOwnerVerificationReferralCodeAdvance,
    StoreOwnerVerificationReferralCodeAdvanceVariables
  >(GET_STORE_OWNER_REFERRAL_CODE_VERIFICATION, {
    variables: {
      pagination: { skip: page * rowsPerPage, first: rowsPerPage },
      filter: queryVerifVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: photoData,
    loading: photoLoading,
    refetch: photoRefetch,
  } = useQuery<
    ReferralStorePhotoVerifAdvance,
    ReferralStorePhotoVerifAdvanceVariables
  >(GET_REFERRAL_STORE_PHOTO_VERIF, {
    variables: {
      requestVerificationFilter: queryPhotoVerifVariables,
      pagination: { skip: page * rowsPerPage, first: rowsPerPage },
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(
    (_skip?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            ...queryVariables,
            pagination: { skip: page * rowsPerPage, first: rowsPerPage },
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [page, rowsPerPage, refetch, queryVariables],
  );

  const refetchOrderData = useCallback(
    (_skip?: number) => {
      const asyncRefetch = async () => {
        try {
          await orderRefetch?.({
            ...queryOrderVariables,
            pagination: { skip: page * rowsPerPage, first: rowsPerPage },
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [page, rowsPerPage, orderRefetch, queryOrderVariables],
  );

  const refetchVerifData = useCallback(
    (_skip?: number) => {
      const asyncRefetch = async () => {
        try {
          await verifRefetch?.({
            pagination: { skip: page * rowsPerPage, first: rowsPerPage },
            filter: queryVerifVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [page, rowsPerPage, verifRefetch, queryVerifVariables],
  );

  const refetchPhotoData = useCallback(
    (_skip?: number) => {
      const asyncRefetch = async () => {
        try {
          await photoRefetch?.({
            requestVerificationFilter: queryPhotoVerifVariables,
            pagination: { skip: page * rowsPerPage, first: rowsPerPage },
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [page, rowsPerPage, photoRefetch, queryPhotoVerifVariables],
  );

  useEffect(() => {
    refetchData();
    refetchOrderData();
    refetchVerifData();
    refetchPhotoData();
  }, [refetchData, refetchOrderData, refetchVerifData, refetchPhotoData]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
    refetchOrderData(page * rowsPerPage);
    refetchVerifData(page * rowsPerPage);
    refetchPhotoData(page * rowsPerPage);
  }, [
    refetchData,
    refetchOrderData,
    refetchVerifData,
    refetchPhotoData,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (dateFrom && dateUntil) {
      setSearchByDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
      setSearchByDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchByDateFrom(null);
      setSearchByDateUntil(null);
    }
  }, [dateFrom, dateUntil]);

  const handlePhone = (
    phone: string,
    setStateFunction: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const phoneRegex = /^[0-9]*$/;

    if (phoneRegex.test(phone)) {
      setStateFunction(phone);
    }
  };

  const onSearch = useCallback(() => {
    setQueryVariables({
      storeCode: storeCode || null,
      storeName: storeName || null,
      redeemSalesmanCode: salesmanCode || null,
      startDate: searchByDateFrom?.toISOString() || null,
      endDate: searchByDateUntil?.toISOString() || null,
      redeemStatus: (selectedStatus?.value as RedeemStatus) || null,
      source: (selectedSource?.value as LoyaltySource) || null,
      userName: userName || null,
      phoneNumber: phone || null,
    });
    setPage(0);
  }, [
    storeCode,
    storeName,
    salesmanCode,
    searchByDateFrom,
    searchByDateUntil,
    selectedStatus,
    selectedSource,
    userName,
    phone,
    setQueryVariables,
  ]);

  const onSearchOrder = useCallback(() => {
    setQueryOrderVariables({
      distributorName: distributorName,
      orderCode: orderCode || null,
      distributorCode: isNaN(Number.parseInt(distributorCode, 10))
        ? null
        : Number.parseInt(distributorCode, 10),
      orderDateInput:
        dateFromOrder == null || dateUntilOrder == null
          ? null
          : {
              startDate: dateFromOrder,
              endDate: dateUntilOrder,
            },
      salesmanReferralCode: salesmanCodeOrder,
      salesmanCode: salesmanCode,
      statusOrder:
        selectedOrderStatus?.value === OrderStatus.CANCELLED
          ? OrderStatus.CANCELLED
          : selectedOrderStatus?.value === OrderStatus.CLOSE
          ? OrderStatus.CLOSE
          : selectedOrderStatus?.value === OrderStatus.OPEN
          ? OrderStatus.OPEN
          : selectedOrderStatus?.value === OrderStatus.PARTIAL
          ? OrderStatus.PARTIAL
          : selectedOrderStatus?.value === OrderStatus.PENDING
          ? OrderStatus.PENDING
          : null,
      storeCode: storeCodeOrder || null,
      storeName: storeNameOrder || null,
    });
    setPage(0);
  }, [
    distributorName,
    orderCode,
    dateFromOrder,
    distributorCode,
    dateUntilOrder,
    selectedOrderStatus,
    salesmanCodeOrder,
    salesmanCode,
    storeCodeOrder,
    storeNameOrder,
    setQueryOrderVariables,
  ]);

  const onSearchVerif = useCallback(() => {
    setQueryVerifVariables({
      dateOfFiling:
        filingDateFromVerif == null || filingDateUntilVerif == null
          ? null
          : {
              startDate: filingDateFromVerif,
              endDate: filingDateUntilVerif,
            },
      phoneNumber: phoneVerif || null,
      salesmanReferralCode: salesmanCodeVerif || null,
      storeCode: storeCodeVerif || null,
      storeName: storeNameVerif || null,
      verificationStatus:
        selectedVerifStatus?.value === 'Terverifikasi'
          ? true
          : selectedVerifStatus?.value === 'Belum Terverifikasi'
          ? false
          : null,
      verifiedDate:
        verifiedDateFromVerif == null || verifiedDateUntilVerif == null
          ? null
          : {
              startDate: verifiedDateFromVerif,
              endDate: verifiedDateUntilVerif,
            },
    });
    setPage(0);
  }, [
    filingDateFromVerif,
    filingDateUntilVerif,
    phoneVerif,
    salesmanCodeVerif,
    storeCodeVerif,
    storeNameVerif,
    selectedVerifStatus,
    verifiedDateFromVerif,
    verifiedDateUntilVerif,
    setQueryVerifVariables,
  ]);

  const onSearchPhoto = useCallback(() => {
    setQueryPhotoVerifVariables({
      managerStoreCode: storeCodePhoto || null,
      storeName: storeNamePhoto || null,
      phoneNumber: phonePhoto || null,
      firstRevalidationSubmitDate:
        filingDateFromPhoto == null || filingDateUntilPhoto == null
          ? null
          : {
              startDate: filingDateFromPhoto,
              endDate: filingDateUntilPhoto,
            },
      storePhotoSalesmanReferralCode: salesmanCodePhoto || null,
      storeRequestVerifStatus:
        (selectedVerifPhotoStatus?.value as StatusVerify) || null,
    });
    setPage(0);
  }, [
    storeCodePhoto,
    storeNamePhoto,
    phonePhoto,
    filingDateFromPhoto,
    filingDateUntilPhoto,
    salesmanCodePhoto,
    selectedVerifPhotoStatus,
    setQueryPhotoVerifVariables,
  ]);

  const onClear = useCallback(() => {
    setStoreName('');
    setStoreCode('');
    setSelectedStatus(null);
    setSelectedSource(null);
    setDateFrom(null);
    setDateUntil(null);
    setSalesmanCode('');
    setUserName('');
    setPhone('');
    setQueryVariables({
      storeCode: null,
      storeName: null,
      redeemSalesmanCode: null,
      startDate: null,
      endDate: null,
      redeemStatus: null,
      userName: null,
      phoneNumber: null,
    });
    refetchData();
  }, [refetchData]);

  const onClearOrder = useCallback(() => {
    setStoreCodeOrder('');
    setStoreNameOrder('');
    setDateFromOrder(null);
    setDateUntilOrder(null);
    setSalesmanCodeOrder('');
    setSalesmanCode('');
    setOrderCode('');
    setDistributorCode('');
    setDistributorName('');
    setSelectedOrderStatus(null);
    setQueryOrderVariables({
      distributorCode: null,
      distributorName: null,
      orderCode: null,
      orderDateInput: null,
      salesmanReferralCode: null,
      statusOrder: null,
      storeCode: null,
      storeName: null,
    });
    refetchOrderData();
  }, [refetchOrderData]);

  const onClearVerif = useCallback(() => {
    setFilingDateFromVerif(null);
    setFilingDateUntilVerif(null);
    setPhoneVerif('');
    setSalesmanCodeVerif('');
    setStoreCodeVerif('');
    setStoreNameVerif('');
    setSelectedVerifStatus(null);
    setVerifiedDateFromVerif(null);
    setVerifiedDateUntilVerif(null);
    setQueryVerifVariables({
      dateOfFiling: null,
      phoneNumber: null,
      salesmanReferralCode: null,
      storeCode: null,
      storeName: null,
      verificationStatus: null,
      verifiedDate: null,
    });
    refetchVerifData();
  }, [refetchVerifData]);

  const onClearPhoto = useCallback(() => {
    setStoreCodePhoto('');
    setStoreNamePhoto('');
    setPhonePhoto('');
    setFilingDateFromPhoto(null);
    setFilingDateUntilPhoto(null);
    setSalesmanCodePhoto('');
    setSelectedVerifPhotoStatus(null);
    setQueryPhotoVerifVariables({
      managerStoreCode: null,
      storeName: null,
      phoneNumber: null,
      firstRevalidationSubmitDate: null,
      storePhotoSalesmanReferralCode: null,
      storeRequestVerifStatus: null,
    });
    refetchPhotoData();
  }, [refetchPhotoData]);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const onDownloadError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunduh file', 'download file']));
    setErrorInstance(error);
  }, []);

  const saveData = useCallback(
    (historyData: DownloadPointRedeemHistory) => {
      if (dateFrom && dateUntil) {
        saveAs(
          historyData.downloadLoyaltyPointRedeemHistory.link,
          ' Salesman Referral Code ' +
            new Date(dateFrom) +
            '-' +
            new Date(dateUntil) +
            '.csv',
        );
      } else {
        saveAs(
          historyData.downloadLoyaltyPointRedeemHistory.link,
          ' Salesman Referral Code.csv',
        );
      }
    },
    [dateFrom, dateUntil],
  );

  const saveDataOrder = useCallback(
    (data: DownloadOrderReferralCodeOnly) => {
      if (dateFromOrder && dateUntilOrder) {
        saveAs(
          data.downloadOrderReferralCodeOnly.link,
          ' Salesman Referral Code (Order) ' +
            new Date(dateFromOrder) +
            '-' +
            new Date(dateUntilOrder) +
            '.csv',
        );
      } else {
        saveAs(
          data.downloadOrderReferralCodeOnly.link,
          ' Salesman Referral Code (Order).csv',
        );
      }
    },
    [dateFromOrder, dateUntilOrder],
  );

  const saveDataVerif = useCallback(
    (data: DownloadStoreOwnerRequestVerification) => {
      if (filingDateFromVerif && filingDateUntilVerif) {
        saveAs(
          data.downloadStoreOwnerRequestVerification.link,
          ' Salesman Referral Code (Verif) ' +
            new Date(filingDateFromVerif) +
            '-' +
            new Date(filingDateUntilVerif) +
            '.csv',
        );
      } else {
        saveAs(
          data.downloadStoreOwnerRequestVerification.link,
          ' Salesman Referral Code (Verif).csv',
        );
      }
    },
    [filingDateFromVerif, filingDateUntilVerif],
  );

  const saveDataPhoto = useCallback(
    (data: DownloadReferralSalesmanVerifStore) => {
      if (filingDateFromPhoto && filingDateUntilPhoto) {
        saveAs(
          data.downloadReferralSalesmanVerifStore.link,
          ' Salesman Referral Code (Verif Foto Toko) ' +
            new Date(filingDateFromPhoto) +
            '-' +
            new Date(filingDateUntilPhoto) +
            '.csv',
        );
      } else {
        saveAs(
          data.downloadReferralSalesmanVerifStore.link,
          ' Salesman Referral Code (Verif Foto Toko).csv',
        );
      }
    },
    [filingDateFromPhoto, filingDateUntilPhoto],
  );

  const [getExportLink, { loading: exportLinkLoading }] = useLazyQuery<
    DownloadPointRedeemHistory,
    DownloadPointRedeemHistoryVariables
  >(DOWNLOAD_POINT_HISTORY_REDEEM, {
    onCompleted: saveData,
    onError: onDownloadError,
  });

  const [
    getOrderExportLink,
    { loading: orderExportLinkLoading },
  ] = useLazyQuery<
    DownloadOrderReferralCodeOnly,
    DownloadOrderReferralCodeOnlyVariables
  >(DOWNLOAD_ORDER_REFERRAL_CODE_ONLY, {
    onCompleted: saveDataOrder,
    onError: onDownloadError,
  });

  const [getVerifExportLink] = useLazyQuery<
    DownloadStoreOwnerRequestVerification,
    DownloadStoreOwnerRequestVerificationVariables
  >(DOWNLOAD_STORE_OWNER_REQUEST_VERIFICATION, {
    onCompleted: saveDataVerif,
    onError: onDownloadError,
  });

  const [getPhotoExportLink] = useLazyQuery<
    DownloadReferralSalesmanVerifStore,
    DownloadReferralSalesmanVerifStoreVariables
  >(DOWNLOAD_REFERRAL_STORE_PHOTO_VERIF, {
    onCompleted: saveDataPhoto,
    onError: onDownloadError,
  });

  const refetchLink = useCallback(() => {
    getExportLink({
      variables: {
        date:
          dateFrom && dateUntil
            ? {
                endDate: dateUntil,
                startDate: dateFrom,
              }
            : null,
        storeCode: storeCode || null,
        storeName: storeName || null,
        redeemStatus: (selectedStatus?.value as RedeemStatus) || null,
        source: (selectedSource?.value as LoyaltySource) || null,
        redeemSalesmanCode: salesmanCode,
        phoneNumber: phone,
        userName: userName,
      },
    });
  }, [
    getExportLink,
    storeCode,
    storeName,
    dateFrom,
    dateUntil,
    selectedStatus,
    selectedSource,
    salesmanCode,
    phone,
    userName,
  ]);

  const refetchOrderLink = useCallback(() => {
    getOrderExportLink({
      variables: {
        distributorCode: isNaN(Number.parseInt(distributorCode, 10))
          ? null
          : Number.parseInt(distributorCode, 10),
        distributorName: distributorName,
        orderCode: orderCode,
        orderDateInput:
          dateFromOrder == null || dateUntilOrder == null
            ? null
            : {
                startDate: dateFromOrder,
                endDate: dateUntilOrder,
              },
        salesmanReferralCode: salesmanCodeOrder,
        salesmanCode: salesmanCode,
        statusOrder:
          selectedOrderStatus?.value === OrderStatus.CANCELLED
            ? OrderStatus.CANCELLED
            : selectedOrderStatus?.value === OrderStatus.CLOSE
            ? OrderStatus.CLOSE
            : selectedOrderStatus?.value === OrderStatus.OPEN
            ? OrderStatus.OPEN
            : selectedOrderStatus?.value === OrderStatus.PARTIAL
            ? OrderStatus.PARTIAL
            : selectedOrderStatus?.value === OrderStatus.PENDING
            ? OrderStatus.PENDING
            : null,
        storeCode: storeCodeOrder,
        storeName: storeNameOrder,
      },
    });
  }, [
    getOrderExportLink,
    distributorName,
    orderCode,
    dateFromOrder,
    distributorCode,
    dateUntilOrder,
    selectedOrderStatus,
    salesmanCodeOrder,
    salesmanCode,
    storeCodeOrder,
    storeNameOrder,
  ]);

  const refetchVerifLink = useCallback(() => {
    getVerifExportLink({
      variables: {
        filter: queryVerifVariables,
      },
    });
  }, [getVerifExportLink, queryVerifVariables]);

  const refetchPhotoLink = useCallback(() => {
    getPhotoExportLink({
      variables: {
        requestVerificationFilter: queryPhotoVerifVariables,
      },
    });
  }, [getPhotoExportLink, queryPhotoVerifVariables]);

  let onExportPress = useCallback(() => {
    if (activeContent === ActiveContent.POINT) {
      refetchLink();
    } else if (activeContent === ActiveContent.ORDER) {
      refetchOrderLink();
    } else if (activeContent === ActiveContent.VERIF) {
      refetchVerifLink();
    } else {
      refetchPhotoLink();
    }
  }, [
    refetchLink,
    refetchOrderLink,
    refetchVerifLink,
    refetchPhotoLink,
    activeContent,
  ]);

  let rightSide = () => {
    if (activeContent === ActiveContent.POINT) {
      return (
        <>
          <View style={styles.separator}>
            <SearchByDateRange
              data-cy="slider-search-date"
              from={dateFrom}
              until={dateUntil}
              setFrom={setDateFrom}
              setUntil={setDateUntil}
              setDisabled={setSearchDisabled}
            />
          </View>
          <View style={styles.separator}>
            <Dropdown
              data-cy="slider-search-status"
              type="side"
              title={t(['Cari Status Redeem', 'Search Redeem Status'])}
              options={statusOption}
              onSelect={setSelectedStatus}
              selectedOption={selectedStatus?.label ?? undefined}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari No HP', 'Search by Phone'])}
              value={phone}
              setValue={(value) => handlePhone(value, setPhone)}
            />
          </View>
          <View style={styles.separator}>
            <Dropdown
              data-cy="slider-search-status"
              type="side"
              title={t(['Cari Sumber', 'Search Source'])}
              options={sourceOption}
              onSelect={setSelectedSource}
              selectedOption={selectedSource?.label ?? undefined}
            />
          </View>
        </>
      );
    } else if (activeContent === ActiveContent.ORDER) {
      return (
        <>
          <View style={styles.separator}>
            <Dropdown
              data-cy="slider-search-status"
              type="side"
              title={t(['Cari Status Order', 'Search Order Status'])}
              options={orderStatus}
              onSelect={setSelectedOrderStatus}
              selectedOption={selectedOrderStatus?.label ?? undefined}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Order', 'Search by Order Code'])}
              value={orderCode}
              setValue={setOrderCode}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Distributor', 'Search by Distributor Code'])}
              value={distributorCode}
              setValue={setDistributorCode}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Nama Distributor', 'Search by Distributor Name'])}
              value={distributorName}
              setValue={setDistributorName}
            />
          </View>
        </>
      );
    } else if (activeContent === ActiveContent.VERIF) {
      return (
        <>
          <View style={styles.separator}>
            <SearchByDateRange
              title={t(['Cari Tanggal Pengajuan', 'Search by Request Date'])}
              from={filingDateFromVerif}
              until={filingDateUntilVerif}
              setFrom={(value: Date) => {
                value.setUTCHours(0, 0, 0, 0);
                setFilingDateFromVerif(value);
              }}
              setUntil={(value: Date) => {
                value.setUTCHours(0, 0, 0, 0);
                setFilingDateUntilVerif(value);
              }}
              setDisabled={setSearchDisabled}
            />
          </View>
          <View style={styles.separator}>
            <SearchByDateRange
              title={t([
                'Cari Tanggal Diverifikasi',
                'Search by Verified Date',
              ])}
              from={verifiedDateFromVerif}
              until={verifiedDateUntilVerif}
              setFrom={(value: Date) => {
                value.setUTCHours(0, 0, 0, 0);
                setVerifiedDateFromVerif(value);
              }}
              setUntil={(value: Date) => {
                value.setUTCHours(0, 0, 0, 0);
                setVerifiedDateUntilVerif(value);
              }}
              setDisabled={setSearchDisabled}
            />
          </View>

          <View style={styles.separator}>
            <Dropdown
              data-cy="slider-search-status"
              type="side"
              title={t([
                'Cari Status Verifikasi',
                'Search by Verification Status',
              ])}
              options={verifStatus}
              onSelect={setSelectedVerifStatus}
              selectedOption={selectedVerifStatus?.label ?? undefined}
            />
          </View>
        </>
      );
    } else {
      return (
        <>
          <View style={styles.separator}>
            <SearchByDateRange
              title={t(['Cari Tanggal Pengajuan', 'Search by Request Date'])}
              from={filingDateFromPhoto}
              until={filingDateUntilPhoto}
              setFrom={(value: Date) => {
                value.setUTCHours(0, 0, 0, 0);
                setFilingDateFromPhoto(value);
              }}
              setUntil={(value: Date) => {
                value.setUTCHours(0, 0, 0, 0);
                setFilingDateUntilPhoto(value);
              }}
              setDisabled={setSearchDisabled}
            />
          </View>
          <View style={styles.separator}>
            <Dropdown
              data-cy="slider-search-status"
              type="side"
              title={t([
                'Cari Status Verifikasi',
                'Search by Verification Status',
              ])}
              options={tokoStatusOptions}
              onSelect={setSelectedVerifPhotoStatus}
              selectedOption={selectedVerifPhotoStatus?.label ?? undefined}
            />
          </View>
        </>
      );
    }
  };

  let leftSide = () => {
    if (activeContent === ActiveContent.POINT) {
      return (
        <>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Toko', 'Search by Store Code'])}
              value={storeCode}
              setValue={setStoreCode}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Nama Toko', 'Search by Store Name'])}
              value={storeName}
              setValue={setStoreName}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Salesman', 'Search by Salesman Code'])}
              value={salesmanCode}
              setValue={setSalesmanCode}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Nama User', 'Search by User Name'])}
              value={userName}
              setValue={setUserName}
            />
          </View>
        </>
      );
    } else if (activeContent === ActiveContent.ORDER) {
      return (
        <>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Toko', 'Search by Store Code'])}
              value={storeCodeOrder}
              setValue={setStoreCodeOrder}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Nama Toko', 'Search by Store Name'])}
              value={storeNameOrder}
              setValue={setStoreNameOrder}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t([
                'Cari Kode Referral Salesman',
                'Search by Salesman Referral Code',
              ])}
              value={salesmanCodeOrder}
              setValue={setSalesmanCodeOrder}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Salesman', 'Search by Salesman Code'])}
              value={salesmanCode}
              setValue={setSalesmanCode}
            />
          </View>
          <View style={styles.separator}>
            <SearchByDateRange
              data-cy="slider-search-date"
              from={dateFromOrder}
              until={dateUntilOrder}
              setFrom={setDateFromOrder}
              setUntil={setDateUntilOrder}
              setDisabled={setSearchDisabled}
            />
          </View>
        </>
      );
    } else if (activeContent === ActiveContent.VERIF) {
      return (
        <>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Toko', 'Search by Store Code'])}
              value={storeCodeVerif}
              setValue={setStoreCodeVerif}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Nama Toko', 'Search by Store Name'])}
              value={storeNameVerif}
              setValue={setStoreNameVerif}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari No HP', 'Search by Phone'])}
              value={phoneVerif}
              setValue={(value) => handlePhone(value, setPhoneVerif)}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Referral', 'Search by Referral Code'])}
              value={salesmanCodeVerif}
              setValue={setSalesmanCodeVerif}
            />
          </View>
        </>
      );
    } else {
      return (
        <>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Toko', 'Search by Store Code'])}
              value={storeCodePhoto}
              setValue={setStoreCodePhoto}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Nama Toko', 'Search by Store Name'])}
              value={storeNamePhoto}
              setValue={setStoreNamePhoto}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari No HP', 'Search by Phone'])}
              value={phonePhoto}
              setValue={(value) => handlePhone(value, setPhonePhoto)}
            />
          </View>
          <View style={styles.separator}>
            <SearchByText
              label={t(['Cari Kode Referral', 'Search by Referral Code'])}
              value={salesmanCodePhoto}
              setValue={setSalesmanCodePhoto}
            />
          </View>
        </>
      );
    }
  };

  const clearFilter = useCallback(() => {
    onClear();
    onClearOrder();
    onClearVerif();
    onClearPhoto();
  }, [onClear, onClearOrder, onClearVerif, onClearPhoto]);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);

  const renderTable = () => {
    if (activeContent === ActiveContent.POINT) {
      return (
        <Table
          data-cy="salesman-referral-code-table"
          data={data?.loyaltyPointHistoryRedeem.row ?? []}
          dataCount={data?.loyaltyPointHistoryRedeem.total ?? 0}
          error={!!error}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          structure={{
            ...tableStructure,
            redeemDate: {
              ...tableStructure.redeemDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={onRowsChange}
        />
      );
    } else if (activeContent === ActiveContent.ORDER) {
      return (
        <Table
          data={orderData?.getOrderReferralCodeOnly.orders || []}
          dataCount={orderData?.getOrderReferralCodeOnly.total ?? 0}
          error={!!error}
          loading={orderLoading}
          page={page}
          rowsPerPage={rowsPerPage}
          structure={{
            ...orderTableStructure,
            orderDate: {
              ...orderTableStructure.orderDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={onRowsChange}
        />
      );
    } else if (activeContent === ActiveContent.VERIF) {
      return (
        <Table
          data={
            verifData?.storeOwnerVerificationSalesReferralCodeAdvance.row || []
          }
          dataCount={
            verifData?.storeOwnerVerificationSalesReferralCodeAdvance.total ?? 0
          }
          error={!!error}
          loading={verifLoading}
          page={page}
          rowsPerPage={rowsPerPage}
          structure={{
            ...verifTableStructure,
            dateOfFiling: {
              ...verifTableStructure.dateOfFiling,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            verifiedDate: {
              ...verifTableStructure.verifiedDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            verificationStatus: {
              ...verifTableStructure.verificationStatus,
              valuePreProcessor: (value) =>
                value === 'VERIFIED'
                  ? t(['Terverifikasi', 'Verified'])
                  : t(['Belum Terverifikasi', 'Not Verified']),
            },
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={onRowsChange}
        />
      );
    } else {
      return (
        <Table
          data={photoData?.referralStorePhotoVerifAdvance.row || []}
          dataCount={photoData?.referralStorePhotoVerifAdvance.total || 0}
          error={!!error}
          loading={photoLoading}
          page={page}
          rowsPerPage={rowsPerPage}
          structure={{
            ...verifPhotoTableStructure,
            firstRevalidationSubmitDate: {
              ...verifPhotoTableStructure.firstRevalidationSubmitDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={onRowsChange}
        />
      );
    }
  };

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <View style={styles.separator}>
        <SearchHeaderReferral
          title={t(['Kode Referral Salesman', 'Salesman Referral Code'])}
          onSearchPress={() => {
            if (activeContent === ActiveContent.POINT) {
              onSearch();
            } else if (activeContent === ActiveContent.ORDER) {
              onSearchOrder();
            } else if (activeContent === ActiveContent.VERIF) {
              onSearchVerif();
            } else {
              onSearchPhoto();
            }
          }}
          onClearPress={clearFilter}
          containerStyle={styles.searchSection}
          disabled={searchDisabled}
          onChangeTab={(value: ActiveContent) => {
            setActiveContent(value);
            setPage(0);
            clearFilter();
          }}
        >
          {leftSide()}
          {rightSide()}
        </SearchHeaderReferral>
        <View style={styles.exportButtonContainer}>
          <Button
            data-cy="request-agent-download"
            title={t(['Ekspor Semua Data', 'Export All Data'])}
            onPress={onExportPress}
            style={styles.exportButton}
            isLoading={exportLinkLoading || orderExportLinkLoading}
          />
        </View>
      </View>
      {renderTable()}
      {!!error && (
        <ErrorMessage
          error={error}
          action={t([
            'mengambil data kode referral salesman',
            'retrieve the salesman referral code data',
          ])}
          onPress={refetchCurrentPage}
        />
      )}
      <ErrorModal
        action={errorAction}
        open={errorOpen}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  searchSection: {
    paddingBottom: spacing.xsmall,
  },
  separator: {
    paddingBottom: spacing.medium,
    marginBottom: spacing.xxsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  exportButtonContainer: {
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
  },
  exportButton: {
    padding: 0,
    minWidth: 200,
  },
});

export default SalesmanReferralCodeScene;
